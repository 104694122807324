import { useAuth } from "./useAuth";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { Loader } from "../../components/loading/Loader";
import { useRouter } from "next/router";
import FourOhThreePage from "../../components/auth/403Page";
import { isError } from "lodash";

// What routes within Tether can be accessed by IFrame users
const SUPPORTED_ROUTES = ["/ext/"];

const SUPPORTED_HOSTED_URLS = [
  /^https:\/\/[a-zA-Z0-9-]+\.ricohspaces\.app$/,
  /^http:\/\/[a-zA-Z0-9-]+\.ricohspaces\.app:3002$/,
  /^http:\/\/localhost:3002$/,
];

// Match embed.tetherhq.com or embed.[dev|staging|etc].tetherhq.com
const EXT_HOST_REGEX = /^embed\.([a-zA-Z]+\.)?tetherhq\.com/;

enum IFrameMessageType {
  Auth = "auth",
  Error = "error",
  Ready = "ready",
  Navigate = "navigate",
  LoadingStateChange = "loading_state_change",
  UserAction = "user_action",
}

interface IFrameMessage {
  message_type: IFrameMessageType;
}

interface IFrameAuthMessage extends IFrameMessage {
  message_type: IFrameMessageType.Auth;
  authorization_code: string;
}

interface IFrameErrorMessage extends IFrameMessage {
  message_type: IFrameMessageType.Error;
  hasError: boolean;
  error: string;
  source: string;
}

interface IFrameReadyMessage extends IFrameMessage {
  message_type: IFrameMessageType.Ready;
  ready: boolean;
  client_id: string;
}

interface IFrameNavigateMessage extends IFrameMessage {
  message_type: IFrameMessageType.Navigate;
  path: string;
  authorization_code?: string;
}

export interface IFrameUserActionMessage extends IFrameMessage {
  message_type: IFrameMessageType.UserAction;
  action: string;
  data: object | string;
}

export interface IFrameLoadingStateChangeMessage extends IFrameMessage {
  message_type: IFrameMessageType.LoadingStateChange;
  is_loading: boolean;
  source: string;
}

function isAuthMessage(message: IFrameMessage): message is IFrameAuthMessage {
  if (message.message_type === IFrameMessageType.Auth) {
    if (!message || typeof (message as IFrameAuthMessage).authorization_code !== "string") {
      throw new Error("Auth message must have an authorization code");
    }

    return true;
  }

  // Legacy support
  return (
    message.message_type === undefined &&
    typeof (message as IFrameAuthMessage).authorization_code === "string"
  );
}

function isNavigateMessage(message: IFrameMessage): message is IFrameNavigateMessage {
  if (message.message_type !== IFrameMessageType.Navigate) return false;

  if (!(message as IFrameNavigateMessage).path) {
    throw new Error("Navigate message must have a path");
  }

  return true;
}

// Notify that the parent window is loaded and awaiting an auth code
const notifyReady = () => {
  if (window.top?.postMessage) {
    console.log("Sending ready message", { origin: window.location.origin });
    window.top.postMessage(
      { message_type: IFrameMessageType.Ready, ready: true, client_id: process.env.NEXT_PUBLIC_CLIENT_ID },
      "*"
    );
  }
};

function sendErrorMessage(error: string, origin: string, source: string) {
  console.log("Sending Error message to parent", { error, source });
  if (window.top?.postMessage) {
    window.top.postMessage({ message_type: IFrameMessageType.Error, hasError: true, error, source }, origin);
  }
}

function notifyLoading(origin: string, source: string) {
  console.log("Notifying user data loading");
  if (window.top?.postMessage) {
    const message: IFrameLoadingStateChangeMessage = {
      message_type: IFrameMessageType.LoadingStateChange,
      is_loading: true,
      source,
    };
    window.top.postMessage(message, origin);
  }
}

function notifyLoadingComplete(origin: string, source: string) {
  console.log("Notifying user data loading complete");
  if (window.top?.postMessage) {
    const message: IFrameLoadingStateChangeMessage = {
      message_type: IFrameMessageType.LoadingStateChange,
      is_loading: false,
      source,
    };
    window.top.postMessage(message, origin);
  }
}

// Used to export as a context to allow triggering of post message via useIFrameMessaging
// All methods are option so that it doesn't break if the context is missing
export interface EmbeddedAuthGuardContextData {
  sendPostMessage: (message: IFrameMessage) => void;
  sendLoadingStarted: (source: string) => void;
  sendLoadingComplete: (source: string) => void;
  sendUserAction: (action: string, data: object | string) => void;
  sendErrorMessage: (error: string, source: string) => void;
  isEmbedHost: boolean;
}

export const EmbeddedAuthGuardContext = createContext({} as EmbeddedAuthGuardContextData);

export const EmbeddedAuthGuard: FC = ({ children }) => {
  const { user, isLoadingAuth, signIn } = useAuth();
  const router = useRouter();
  const [authReceived, setAuthReceived] = useState(false);
  const [parentOrigin, setParentOrigin] = useState<string>();

  const isEmbedHost = Boolean(
    typeof window !== "undefined" &&
      !!window?.location?.hostname &&
      EXT_HOST_REGEX.test(window.location.hostname)
  );

  const processIFrameMessage = useCallback(
    async (data: IFrameMessage, origin: string) => {
      try {
        // Process a request to signin with an auth code. Doesn't matter if the user is already signed in, this will just overwrite the existing login
        if (isAuthMessage(data)) {
          try {
            setParentOrigin(origin); // Store origin for future messages
            notifyLoading(origin, "auth"); // Notify parent that user state is loading
            await signIn({ authorizationCode: data.authorization_code });
          } catch (e) {
            // Disabled for now as it's firing on successful auth as well (I think it's a local react issue but I'm not sure)
            // sendErrorMessage((e as Error)?.message, origin, "auth");
          } finally {
            setAuthReceived(true);
            notifyLoadingComplete(origin, "auth"); // Notify parent that user state is loaded
          }
          return;
        }

        // Process a request to navigate to a new page
        if (isNavigateMessage(data)) {
          // If a new authcode is provided, sign in with it first
          if (user && data.authorization_code) {
            setParentOrigin(origin);
            // Already signed in but have been given a new auth code (probably to change orgs)
            setAuthReceived(false);
            await signIn({ authorizationCode: data.authorization_code });
          }

          // Navigate to the new path
          router.push(data.path);

          // Remove loading spinner if auth changed
          setAuthReceived(true);

          return;
        }
      } catch (e) {
        console.error("Error processing IFrame message", e);
        if (isError(e)) {
          // Disabled for now to not pollute important errors
          // sendErrorMessage((e as Error).message, origin, "auth");
        }
      }

      console.error("Invalid message received", { data });
      // Disabled for now to not pollute important errors
      // sendErrorMessage("Invalid message received", origin, "auth");
    },
    [signIn, setAuthReceived, router, user, setParentOrigin]
  );

  // The message handler to collect a post message
  const iframeMessageHandler = useCallback(
    async (event: MessageEvent<IFrameAuthMessage>) => {
      // Validate post message origin
      if (!SUPPORTED_HOSTED_URLS.find((regex) => regex.test(event.origin))) {
        console.log("Origin not supported", { origin: event.origin });
        return;
      }

      await processIFrameMessage(event.data, event.origin);
    },
    [processIFrameMessage]
  );

  useEffect(() => {
    // Don't run if we're not on an embed.tetherhq.com route
    if (!isEmbedHost) {
      return;
    }

    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("message", iframeMessageHandler, false);

    if (!user) {
      notifyReady();
    }

    return () => {
      window.removeEventListener("message", iframeMessageHandler);
    };
  }, [iframeMessageHandler, isEmbedHost, user]);

  // Setup a postmessage function that can be called by children
  const sendPostMessage = useCallback(
    (message: IFrameMessage) => {
      if (!parentOrigin) {
        // If we don't have a parent origin, we can't send messages for security purposes
        console.error("Parent origin not set, cannot send message", { message });
        return;
      }

      if (window.top?.postMessage) {
        console.log("Sending message to parent", { message });
        window.top.postMessage(message, parentOrigin);
      }
    },
    [parentOrigin]
  );

  if (isLoadingAuth || !authReceived || typeof window === "undefined") {
    return <Loader height="100vh" />;
  }

  // Block IFrame users from accessing routes that are not supported
  // TODO: This needs to be updated once a new scope is created for IFrame users.
  if (
    !isEmbedHost ||
    !user ||
    !router.pathname ||
    !SUPPORTED_ROUTES.some((route) => router.pathname.startsWith(route))
  ) {
    return <FourOhThreePage />;
  }

  return (
    <EmbeddedAuthGuardContext.Provider
      value={{
        isEmbedHost,
        sendPostMessage,
        sendLoadingStarted: (source) =>
          sendPostMessage({
            message_type: IFrameMessageType.LoadingStateChange,
            is_loading: true,
            source,
          } as IFrameLoadingStateChangeMessage),
        sendLoadingComplete: (source) =>
          sendPostMessage({
            message_type: IFrameMessageType.LoadingStateChange,
            is_loading: false,
            source,
          } as IFrameLoadingStateChangeMessage),
        sendUserAction: (action, data) =>
          sendPostMessage({
            message_type: IFrameMessageType.UserAction,
            action,
            data,
          } as IFrameUserActionMessage),
        sendErrorMessage: (error, source) =>
          sendPostMessage({
            message_type: IFrameMessageType.Error,
            hasError: true,
            error,
            source,
          } as IFrameErrorMessage),
      }}
    >
      {children}
    </EmbeddedAuthGuardContext.Provider>
  );
};
