import * as React from "react";

const SpeedometerSidebarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4322 5.94278L8.73528 9.27444C8.51056 9.17194 8.26306 9.11111 8 9.11111C7.01806 9.11111 6.22222 9.90694 6.22222 10.8889C6.22222 11.8708 7.01806 12.6667 8 12.6667C8.98194 12.6667 9.77778 11.8708 9.77778 10.8889C9.77778 10.4928 9.64389 10.1306 9.42472 9.835L12.1231 6.50167C12.2775 6.31111 12.2481 6.03111 12.0569 5.87667C11.8656 5.72278 11.5867 5.75167 11.4322 5.94278ZM8 11.7778C7.50917 11.7778 7.11111 11.3797 7.11111 10.8889C7.11111 10.3981 7.50917 10 8 10C8.49083 10 8.88889 10.3981 8.88889 10.8889C8.88889 11.3797 8.49083 11.7778 8 11.7778ZM8 2C3.58167 2 0 5.58167 0 10C0 11.4667 0.395833 12.8406 1.085 14.0222C1.24083 14.2894 1.53778 14.4444 1.84722 14.4444H14.1528C14.4622 14.4444 14.7592 14.2894 14.915 14.0222C15.6042 12.8406 16 11.4667 16 10C16 5.58167 12.4183 2 8 2ZM14.1528 13.5556L1.85278 13.5744C1.22222 12.4931 0.888889 11.2572 0.888889 10C0.888889 6.07889 4.07889 2.88889 8 2.88889C11.9211 2.88889 15.1111 6.07889 15.1111 10C15.1111 11.2572 14.7778 12.4931 14.1528 13.5556Z"
      fill="#F3F3F3"
    />
  </svg>
);

export default SpeedometerSidebarIcon;
