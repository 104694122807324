import type { AppProps } from "next/app";
import { AuthProvider } from "../lib/auth/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppChakraProvider } from "../components/global-styles/AppChakraProvider";
import { AuthGuard } from "../lib/auth/AuthGuard";
import "../styles/globalStyles.css";
import Head from "next/head";
import { SidebarLayout } from "../components/sidebar/SidebarLayout";
import { DialogProvider } from "../components/modals/hooks/DialogContext";
import { useRouter } from "next/router";
import AppWrapper from "../components/AppWrapper";
import HubSpotBox from "../components/HubSpotBot";

import { MetricsIntervalContextProvider } from "@tether-web-portal/context-providers";

import "ts-replace-all";
import { EmbeddedAuthGuard } from "../lib/auth/EmbeddedAuthGuard";
import { EmbeddedAuthProvider } from "../lib/auth/EmbeddedAuthProvider";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AnyComponent = Component as any; // this is to fix a shitty typescript error

  const isEmbedded = process.env.NEXT_PUBLIC_PLATFORM_TYPE === "embedded";

  if (isEmbedded) {
    return (
      <>
        <Head>
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" href="/favicon.ico" />

          <meta charSet="utf-8" />
          <title>Tether</title>
        </Head>
        <QueryClientProvider client={queryClient}>
          <AppChakraProvider>
            <EmbeddedAuthProvider>
              <EmbeddedAuthGuard>
                <MetricsIntervalContextProvider>
                  <AnyComponent {...pageProps} />
                </MetricsIntervalContextProvider>
              </EmbeddedAuthGuard>
            </EmbeddedAuthProvider>
          </AppChakraProvider>
        </QueryClientProvider>
      </>
    );
  }

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />

        <meta charSet="utf-8" />
        <title>Tether</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <AppChakraProvider>
          <AuthProvider>
            <AuthGuard>
              <DialogProvider>
                <MetricsIntervalContextProvider>
                  {router.pathname.includes("/ext/") ||
                  router.pathname.includes("/company-settings") ||
                  router.pathname.includes("/customer-order") ? (
                    <AnyComponent {...pageProps} />
                  ) : (
                    <SidebarLayout>
                      <AppWrapper>
                        <AnyComponent {...pageProps} />
                      </AppWrapper>
                    </SidebarLayout>
                  )}
                </MetricsIntervalContextProvider>
              </DialogProvider>
            </AuthGuard>
          </AuthProvider>
        </AppChakraProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />*/}
      </QueryClientProvider>
      <HubSpotBox />
    </>
  );
}
export default MyApp;
