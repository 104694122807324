import { useState } from "react";
import { api } from "../api/api";
import getTokenPayload from "./getTokenPayload";
import authService from "../api/auth/AuthService";
import { useQueryClient } from "react-query";
import { OrgSettingDefaults, OrgSettings } from "../api/auth/OrgSettingService";
import {
  AuthContext,
  AuthProviderProps,
  AuthUser,
  handleLoadUserDetails,
  OrganisationAlertSettings,
  SignInCredentials,
} from "./AuthProvider";
import { UserInvitationByToken } from "../api/auth/types";

/*
 * A light weight auth provider for embedded applications that only allows login with an auth code.
 * No persistant storage of tokens is done, a new token is requested on each page load.
 */

async function assertCorrectScope(scope: string) {
  if (!scope.includes("applicationType=embedded")) {
    throw new Error("Invalid scope, authorization code must be configured for embedded applications");
  }

  if (!scope.includes("organisationId")) {
    throw new Error("Invalid scope, organisationId must be included in the scope");
  }

  return true;
}

export function EmbeddedAuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const [orgSettings, setOrgSettings] = useState<OrgSettings>(OrgSettingDefaults);
  const queryClient = useQueryClient();

  const isAuthenticated = !!user;

  async function signIn({
    authorizationCode,
  }: {
    credentials?: SignInCredentials;
    authorizationCode?: string;
    invitation?: UserInvitationByToken;
    redirectUrl?: string;
  }) {
    if (!authorizationCode) {
      throw new Error("Authorization code is required for embedded applications");
    }

    setIsLoading(true);
    // eslint-disable-next-line prefer-const
    const {
      access_token: token,
      refresh_token: refreshToken,
      scope,
    } = await authService.loginWithAccessCode({ code: authorizationCode });

    await assertCorrectScope(scope);

    const payload = getTokenPayload(token);

    api.setToken(token);
    api.setRefreshToken(refreshToken);
    api.setScope(scope);

    // load org details
    const { user, orgSettings } = await handleLoadUserDetails(payload);

    setOrgSettings(orgSettings);
    setUser(user);

    // Clear all cached data
    queryClient.resetQueries();

    setIsLoading(false);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        signIn,
        isLoadingAuth: isLoading,
        orgSettings,

        // Dummy implementations that can't be used on embedded application
        signUp: () => Promise.resolve(),
        signOut: () => Promise.resolve(),
        processInvite: () => Promise.resolve(undefined),
        acceptInvite: () => Promise.resolve(undefined),
        createAccountFromInvite: () => Promise.resolve(undefined),
        switchOrganisation: () => Promise.resolve(false),
        switchToCustomer: () => Promise.resolve(),
        organisationAlertSettings: {} as OrganisationAlertSettings,
        setOrganisationAlertSettings: () => Promise.resolve(),
        requestPasswordReset: () => Promise.resolve(),
        resetPassword: () => Promise.resolve(),
        verifyResetPasswordToken: () => Promise.resolve(),
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
}
