export enum MetricDataInterval {
  "HOURS_24" = "24HOURS",
  "DAYS_7" = "7DAYS",
  "DAYS_30" = "30DAYS",
  "MONTHS_3" = "3MONTHS",
  "CUSTOM" = "CUSTOM",
}

export const MetricDataIntervalTitleMap: Record<MetricDataInterval, string> = {
  [MetricDataInterval.HOURS_24]: "24 Hours",
  [MetricDataInterval.DAYS_7]: "7 Days",
  [MetricDataInterval.DAYS_30]: "30 Days",
  [MetricDataInterval.MONTHS_3]: "3 Months",
  [MetricDataInterval.CUSTOM]: "Date range",
};
