import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import Link from "next/link";
import { TetherLogo } from "../logo/TetherLogo";

export default function FourOhThreePage() {
  return (
    <Box
      color="gray.300"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      h="100vh"
    >
      <HStack mb={10}>
        <Heading borderRight="1px solid gray" pr={6} mr={4}>
          Access Denied
        </Heading>
        <Text fontSize="14px">{"You don't have permission to access this resource."}</Text>
      </HStack>
      <Link href="/">
        <HStack>
          <TetherLogo height={40} width={150} fill="#171923" />
        </HStack>
      </Link>
    </Box>
  );
}
