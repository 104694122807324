import React, { FunctionComponent, useCallback, useState } from "react";

import { endOfHour, startOfHour, subDays, differenceInDays, format } from "date-fns";

import { MetricDataInterval, MetricDataIntervalTitleMap } from "@tether-web-portal/types/metricsTypes";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";

interface IMetricsIntervalContext {
  metricsNumberOfDaysToShow: number;
  metricsTimeStamps: TimestampRange;
  metricsCurrentDataInterval: MetricDataInterval;
  metricsCurrentDataIntervalLabel: string;
  setMetricsInterval: (interval: MetricDataInterval) => void;
  setMetricsIntervalFromTimestamp: (fromTimestamp: string) => void;
  setCustomMetricsInterval: (fromTimestamp: string, toTimestamp: string) => void;
}
const contextInitialState = (): IMetricsIntervalContext => ({
  metricsNumberOfDaysToShow: 1,
  metricsTimeStamps: {
    fromTimestamp: startOfHour(subDays(new Date(), 1)).toISOString(),
    toTimestamp: endOfHour(new Date()).toISOString(),
  },
  metricsCurrentDataInterval: MetricDataInterval.HOURS_24,
  metricsCurrentDataIntervalLabel: MetricDataIntervalTitleMap[MetricDataInterval.HOURS_24],
  setMetricsInterval: () => null,
  setMetricsIntervalFromTimestamp: () => null,
  setCustomMetricsInterval: () => null,
});

export const MetricsIntervalContext = React.createContext<IMetricsIntervalContext>(contextInitialState());

interface IMetricsIntervalContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
export const MetricsIntervalContextProvider: FunctionComponent<IMetricsIntervalContextProps> = (
  props: IMetricsIntervalContextProps
) => {
  const [contextState, setContextState] = useState<IMetricsIntervalContext>(contextInitialState());

  const setMetricsInterval = useCallback((interval: MetricDataInterval) => {
    const toTimestamp = endOfHour(new Date()).toISOString();
    let numberOfDays = 1;
    switch (interval) {
      case MetricDataInterval.HOURS_24:
        numberOfDays = 1;
        break;
      case MetricDataInterval.DAYS_7:
        numberOfDays = 7;
        break;
      case MetricDataInterval.DAYS_30:
        numberOfDays = 30;
        break;
      case MetricDataInterval.MONTHS_3:
        numberOfDays = 90;
        break;
    }

    const fromTimestamp = startOfHour(subDays(new Date(), numberOfDays)).toISOString();
    setContextState((state) => ({
      ...state,
      metricsNumberOfDaysToShow: numberOfDays,
      metricsFromTimestamp: fromTimestamp,
      metricsToTimestamp: toTimestamp,
      metricsTimeStamps: {
        fromTimestamp,
        toTimestamp,
      },
      metricsCurrentDataInterval: interval,
      metricsCurrentDataIntervalLabel: MetricDataIntervalTitleMap[interval],
    }));
  }, []);

  const setMetricsIntervalFromTimestamp = useCallback(
    (fromTimestamp: string) => {
      const daysBetween = differenceInDays(new Date(), new Date(fromTimestamp));
      if (daysBetween >= 90) {
        setMetricsInterval(MetricDataInterval.MONTHS_3);
      } else if (daysBetween >= 30) {
        setMetricsInterval(MetricDataInterval.DAYS_30);
      } else if (daysBetween > 1) {
        setMetricsInterval(MetricDataInterval.DAYS_7);
      } else {
        setMetricsInterval(MetricDataInterval.HOURS_24);
      }
    },
    [setMetricsInterval]
  );

  const setCustomMetricsInterval = useCallback((fromTimestamp: string, toTimestamp: string) => {
    const fromTime = new Date(fromTimestamp);
    const toTime = new Date(toTimestamp);

    const daysBetween = differenceInDays(toTime, fromTime);
    const label = `${format(fromTime, "dd/M/yyyy")}-${format(toTime, "dd/M/yyyy")}`;

    setContextState((state) => ({
      ...state,
      metricsNumberOfDaysToShow: daysBetween,
      metricsFromTimestamp: fromTimestamp,
      metricsToTimestamp: toTimestamp,
      metricsTimeStamps: {
        fromTimestamp,
        toTimestamp,
      },
      metricsCurrentDataInterval: MetricDataInterval.CUSTOM,
      metricsCurrentDataIntervalLabel: label,
    }));
  }, []);

  return (
    <MetricsIntervalContext.Provider
      value={{
        ...contextState,
        setMetricsInterval,
        setMetricsIntervalFromTimestamp,
        setCustomMetricsInterval,
      }}
    >
      {props.children}
    </MetricsIntervalContext.Provider>
  );
};
