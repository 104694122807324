import { createContext, ReactNode, useEffect, useState } from "react";
import Router from "next/router";
import { api, getCustomerAuth, switchOrganisation } from "../api/api";
import getTokenPayload, { AuthTokenPayload } from "./getTokenPayload";
import { deletePersistentTokens, getPersistedTokens, persistAuthToken } from "./authPersist";
import authService from "../api/auth/AuthService";
import userSettingsService from "../api/user-settings/UserSettingsService";
import { getQueryVariable } from "../utils/getQueryParam";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import { UserInvitationByToken } from "../api/auth/types";
import { useQueryClient } from "react-query";
import orgSettingsService, { OrgSettingDefaults, OrgSettings } from "../api/auth/OrgSettingService";
import { LoginErrorResponse, LoginSuccessResponse } from "../api/types/LoginSuccessResponse";
import { RefreshResponse } from "../api/api-types";
import { Customer } from "../api/properties/hooks";
import { UserRoleType } from "../api/types/IUserOrganisation";

export interface AuthUser {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
  email: string;
  currentOrganisationId: string;
  currentOrganisationRoleId: number;
  currentCustomerId?: string;
}

export interface SignInCredentials {
  username: string;
  password: string;
}

export interface SignUpRequest {
  organisationName: string;
  email: string;
  country?: string;
}

export interface CreateAccountRequest {
  firstName: string;
  lastName: string;
  password: string;
  customerSegment: string;
  numberOfProperties: string;
}

export interface OrganisationAlertSettings {
  COVID_CARE_ALERTS: boolean;
  DEVICE_STATUS_ALERTS: boolean;
}

type ToastType = {
  (options?: UseToastOptions | undefined): string | number | undefined;
};

interface AuthContextData {
  isAuthenticated: boolean;
  user?: AuthUser;
  isAdmin?: boolean;
  signIn: ({
    credentials,
    invitation,
    redirectUrl,
  }: {
    credentials?: SignInCredentials;
    authorizationCode?: string;
    invitation?: UserInvitationByToken;
    redirectUrl?: string;
  }) => Promise<void>;
  signUp: (inputs: SignUpRequest) => Promise<void>;
  signOut: (redirectUrl?: string) => void;
  processInvite: (inviteToken: string) => Promise<UserInvitationByToken | undefined>;
  acceptInvite: (invitation: UserInvitationByToken, inviteToken: string) => Promise<void>;
  createAccountFromInvite: (
    fields: CreateAccountRequest,
    invitation: UserInvitationByToken,
    inviteToken: string
  ) => Promise<void>;
  switchOrganisation: (organisationId: string) => Promise<boolean>;
  switchToCustomer: () => Promise<void>;
  isLoadingAuth: boolean;
  organisationAlertSettings: OrganisationAlertSettings;
  orgSettings: OrgSettings;
  setOrganisationAlertSettings: (settings: OrganisationAlertSettings) => void;
  requestPasswordReset: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  verifyResetPasswordToken: (token: string) => Promise<void>;
}

export interface AuthProviderProps {
  children: ReactNode;
}

interface UserDetailsOrganisation {
  user: AuthUser;
  organisationAlertOverrideSettings: OrganisationAlertSettings;
  orgSettings: OrgSettings;
}

const defaultOrgAlerts = {
  COVID_CARE_ALERTS: false,
  DEVICE_STATUS_ALERTS: true,
};

export const handleLoadUserDetails = async (payload: AuthTokenPayload): Promise<UserDetailsOrganisation> => {
  if (!payload.organisationId) {
    throw new Error("Loading user details without an organisationId");
  }

  localStorage.setItem("previousOrganisationId", payload.organisationId);

  // Start all asynchronous operations in parallel
  const [me, userSettingOverrides, orgSettings] = await Promise.all([
    authService.me(), // First async call
    userSettingsService.userSettingOverride(payload.organisationId), // Second async call
    orgSettingsService.getAllOrgSettings(), // Third async call
  ]);

  // Process userSettingOverrides as before
  const organisationAlertOverrideSettings = userSettingOverrides.reduce((v, item) => {
    return {
      ...v,
      [item.name]: item.value === "true",
    };
  }, defaultOrgAlerts);

  // Return the combined result
  return {
    user: {
      ...me,
      currentOrganisationId: payload.organisationId,
      currentOrganisationRoleId: payload.currentOrganisationRoleId,
    },
    orgSettings,
    organisationAlertOverrideSettings,
  };
};

// const handleLoadCustomer = async (customerId: string): Promise<null> => {
//   const customer = await api.get("/customer/v1/customer/" + customerId);
//   return null;
//   // return {
//   //   user: {
//   //     ...me,
//   //     currentOrganisationId: payload.organisationId,
//   //     currentOrganisationRoleId: payload.currentOrganisationRoleId,
//   //   },
//   //   featureFlags,
//   //   organisationAlertOverrideSettings,
//   // };
// };

export const PORTAL_USER_DATA_STORAGE_KEY = "tether.portalUserData";
export const PORTAL_RECENT_ORGANISATION_STORAGE_KEY = "tether.portalLastOrganisation";

let authChannel: BroadcastChannel;

export const AuthContext = createContext({} as AuthContextData);

const storeUserData = ({
  user,
  organisationAlertOverrideSettings,
}: {
  user: AuthUser;
  organisationAlertOverrideSettings: OrganisationAlertSettings;
}) => {
  const userData = JSON.stringify({
    user,
    organisationAlertOverrideSettings,
  });
  localStorage.setItem(PORTAL_USER_DATA_STORAGE_KEY, userData);
};

const storeLastOrganisationIdForUser = (userId: string, organisationId: string) => {
  const existingStorageString = localStorage.getItem(PORTAL_RECENT_ORGANISATION_STORAGE_KEY);
  let existingUserMap: Record<string, string> = {};
  if (existingStorageString) {
    existingUserMap = JSON.parse(existingStorageString);
  }

  existingUserMap[userId] = organisationId;
  localStorage.setItem(PORTAL_RECENT_ORGANISATION_STORAGE_KEY, JSON.stringify(existingUserMap));
};

const retrieveLastOrganisationIdForUser = (userId: string) => {
  const existingStorageString = localStorage.getItem(PORTAL_RECENT_ORGANISATION_STORAGE_KEY);
  let existingUserMap: Record<string, string> = {};
  if (existingStorageString) {
    existingUserMap = JSON.parse(existingStorageString);
  }

  return existingUserMap[userId];
};

const setupHubspot = async (user: AuthUser) => {
  try {
    // get hubspot token
    const result = await api.post("/v2/hubspot/visitortoken", {});

    if (!result || !result.token) {
      console.log("Could not get hubspot token", result);
      return;
    }

    // @ts-expect-error Property 'hsConversationsSettings' does not exist on type 'Window & typeof globalThis'
    window.hsConversationsSettings = {
      identificationEmail: user.email,
      identificationToken: result.token,
    };
    // @ts-expect-error Property 'HubSpotConversations' does not exist on type 'Window & typeof globalThis'
    window.HubSpotConversations.widget.load();
  } catch (error) {
    console.log("Error configuring hubspot", error);
  }
};

let firstCheckAuthRun = true;

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const [orgSettings, setOrgSettings] = useState<OrgSettings>(OrgSettingDefaults);
  const queryClient = useQueryClient();

  const [organisationAlertSettings, setOrganisationAlertSettings] = useState<OrganisationAlertSettings>(
    defaultOrgAlerts
  );
  const isAuthenticated = !!user;

  const toast = useToast();

  useEffect(() => {
    // if (token) {
    //   // load stored user
    //   const portalUserJSON = localStorage.getItem(PORTAL_USER_DATA_STORAGE_KEY);
    //   if (portalUserJSON) {
    //     const { user, featureFlags, organisationAlertOverrideSettings } = JSON.parse(portalUserJSON) || {};
    //     if (user && featureFlags && organisationAlertOverrideSettings) {
    //       setUser(user);
    //       setFeatureFlags(featureFlags);
    //       setOrganisationAlertSettings(organisationAlertOverrideSettings);
    //       setIsLoading(false);
    //     }
    //   }
    // }

    async function assertCorrectScope(
      token: string | null,
      refreshToken: string | null,
      scope?: string | null
    ) {
      if (!token || !refreshToken || !scope) {
        // logout and go to signin page
        console.log("We aren't logged in. Let's ignore");
        return true;
      }
      const isCompanySettingsPage = window.location.pathname.indexOf("/company-settings") !== -1;
      const isCustomerScope = scope.indexOf("customerId") !== -1;

      if (isCustomerScope && !isCompanySettingsPage) {
        const organisationId =
          getQueryVariable("switchOrganisationId") || localStorage.getItem("previousOrganisationId");
        console.log("need to switch org id", organisationId);
        if (!organisationId) {
          console.log("Can't find org id, logging out");
          throw new Error("Invalid Scope");
        }
        // change to org scope
        await api.attemptToRefreshToken(refreshToken, "organisationId=" + organisationId);
        console.log("Changed to org id and reloading", organisationId);
        window.location.reload();
        return false;
      }

      if (!isCustomerScope && isCompanySettingsPage) {
        console.log("need to switch customer scope id, getting customer");
        try {
          const myCustomer: Customer = await api.get(`/account/v2/customer/current`);
          if (!myCustomer?.id) {
            throw new Error("Invalid Scope");
          }
          console.log("Changing to custoemr", myCustomer.id);
          // change to org scope
          await api.attemptToRefreshToken(refreshToken, "customerId=" + myCustomer.id);
          window.location.reload();
          return false;
        } catch (error) {
          console.log("Error getting customer during scope assertion. signing out", error);
          throw new Error("Invalid Scope");
        }
      }

      // Prevent use of embedded access tokens on the portal
      if (scope.includes("applicationType=embedded")) {
        console.log("Embedded scope not supported");
        throw new Error("Invalid Scope");
      }

      return true;
    }

    async function checkAuth() {
      // if we've checked auth in the last 5 minutes don't bother rechecking
      const lastAuthCheck = localStorage.getItem("lastAuthCheck");
      if (!firstCheckAuthRun && lastAuthCheck) {
        const lastAuthCheckDate = new Date(lastAuthCheck);
        const now = new Date();
        const diff = now.getTime() - lastAuthCheckDate.getTime();
        if (diff < 1000 * 60 * 5) {
          // console.log("Checked recently, don't recheck")
          return;
        }
      }

      localStorage.setItem("lastAuthCheck", new Date().toISOString());
      firstCheckAuthRun = false;

      // console.log("running check auth")
      let { token, refreshToken, scope } = getPersistedTokens();

      try {
        if (!(await assertCorrectScope(token, refreshToken, scope))) {
          return;
        }
      } catch (error) {
        console.log("Error validating scope. Logout", error);
        handleSignOut();
        window.location.href = "/";
        return;
      }

      if (refreshToken && !token) {
        try {
          const data = await api.attemptToRefreshToken(refreshToken, scope);
          token = data.token;
          refreshToken = data.refreshToken;
          scope = data.scope;
        } catch (error) {
          toast({
            title: "Unable to refresh token",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
          console.log("Could not refresh token", error);
        }
      }

      if (token && refreshToken) {
        // check for switching orgs
        const switchOrganisationId = getQueryVariable("switchOrganisationId");
        if (switchOrganisationId) {
          const switched = await switchOrganisationFromUrl(switchOrganisationId, refreshToken, toast);
          if (switched && switched.token) {
            token = switched.token;
            refreshToken = switched.refreshToken;
            scope = switched.scope;
          }
        }

        const tokenUpdated = token !== api.token;

        api.setToken(token as string);
        api.setRefreshToken(refreshToken as string);

        const payload = getTokenPayload(token as string);

        // if its a customer token skip loading details
        if (!payload.organisationId && payload.customerId) {
          if (tokenUpdated) {
            await queryClient.invalidateQueries();
          }
          const me = await authService.me();
          setUser({
            id: me.id,
            firstName: me.firstName,
            lastName: me.lastName,
            avatarUrl: me.avatarUrl,
            email: me.email,
            currentCustomerId: payload.customerId,
            currentOrganisationId: "",
            currentOrganisationRoleId: 0,
          });
          setIsLoading(false);
          return;
        }
        handleLoadUserDetails(payload)
          .then(({ user, organisationAlertOverrideSettings, orgSettings }) => {
            setUser(user);
            setOrganisationAlertSettings(organisationAlertOverrideSettings);
            setOrgSettings(orgSettings);
            storeUserData({ user, organisationAlertOverrideSettings });
            setupHubspot(user);
          })
          .catch((e) => {
            console.log("ERROR LOADING USER DETAILS ERROR", e);
            // toast({
            //   title: "Unable to load user details",
            //   status: "error",
            //   isClosable: true,
            //   position: "top-right",
            // });
            // if (e.message && e.message.indexOf("auth error?")) {
            //   // reset user state we initialised from localstorage
            //   setUser(undefined);
            //   setFeatureFlags(undefined);
            //   setOrganisationAlertSettings(defaultOrgAlerts);

            //   deletePersistentTokens();
            // }
          })
          .finally(() => {
            setIsLoading(false);
          });

        // If the token's updated there's a good chance someone's changed org in a background tab so nuke the queries.
        if (tokenUpdated) {
          queryClient.invalidateQueries();
        }
      } else {
        setIsLoading(false);
      }
    }
    checkAuth();

    // Revalidate auth on window focus in case of changes in another tab
    window.addEventListener("focus", checkAuth);

    return () => window.removeEventListener("focus", checkAuth);
  }, []);

  const handleSignOut = async (redirectUrl?: string) => {
    deletePersistentTokens();
    deletePersistentTokens();
    setOrgSettings(OrgSettingDefaults);

    // authChannel.postMessage("signOut");

    // Clear all cached data
    await queryClient.invalidateQueries();

    setUser(undefined);
    setOrganisationAlertSettings(defaultOrgAlerts);
    localStorage.removeItem(PORTAL_USER_DATA_STORAGE_KEY);
    Router.push(redirectUrl || "/");
  };

  // login/logout cross tab
  // useEffect(() => {
  //   authChannel = new BroadcastChannel("auth");

  //   authChannel.onmessage = (message) => {
  //     switch (message.data) {
  //       case "signOut":
  //         signOut();

  //         authChannel.close();
  //         setUser(undefined);

  //         break;
  //       case "signIn":
  //         // window.location.replace("/dashboard");

  //         break;
  //       default:
  //         break;
  //     }
  //   };
  // }, []);

  async function signIn({
    credentials,
    redirectUrl,
    initialScope,
    authorizationCode,
  }: {
    credentials?: SignInCredentials;
    initialScope?: string;
    redirectUrl?: string;
    authorizationCode?: string;
  }) {
    if (authorizationCode) {
      throw new Error("Authorization code sign-in not supported outside of embedded applications");
    }

    if (!credentials) {
      throw new Error("Credentials are required for sign-in");
    }

    // eslint-disable-next-line prefer-const
    let {
      access_token: token,
      expires_in: maxAge,
      refresh_token: refreshToken,
      scope,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- is this still necessary???
    } = (credentials as any).accessToken
      ? await authService.login({ accessCode: (credentials as any).accessCode, scope: initialScope } as any)
      : await authService.login({
          username: credentials.username,
          password: credentials.password,
          scope: initialScope,
        });

    const switchOrganisationId = getQueryVariable("switchOrganisationId");

    let payload = getTokenPayload(token);

    // see if we need to switch orgs after login
    if (switchOrganisationId) {
      const switched = await switchOrganisationFromUrl(switchOrganisationId, refreshToken, toast);
      if (switched && switched.token) {
        token = switched.token;
        refreshToken = switched.refreshToken;
        payload = getTokenPayload(token);
      }
    } else {
      const lastOrgId = retrieveLastOrganisationIdForUser(payload.userId);

      if (lastOrgId) {
        const switched = await switchOrganisation(refreshToken, lastOrgId);

        if ((switched as LoginErrorResponse).error) {
          console.log("ERROR SWITCHING ORG", switched);
          throw new Error((switched as LoginErrorResponse).error_description);
        }

        if ((switched as LoginSuccessResponse).access_token) {
          token = (switched as LoginSuccessResponse).access_token;
          refreshToken = (switched as LoginSuccessResponse).refresh_token;
          scope = (switched as LoginSuccessResponse).scope;
          payload = getTokenPayload(token);
        }
      }
    }

    api.setToken(token);
    api.setRefreshToken(refreshToken);
    api.setScope(scope);

    persistAuthToken({
      token,
      maxAge,
      refreshToken,
      scope,
    });

    // load org details

    const { user, organisationAlertOverrideSettings, orgSettings } = await handleLoadUserDetails(payload);

    setOrganisationAlertSettings(organisationAlertOverrideSettings);
    setOrgSettings(orgSettings);
    setUser(user);
    storeUserData({ user, organisationAlertOverrideSettings });

    if (redirectUrl) {
      Router.push(redirectUrl);
    }

    // Clear all cached data
    queryClient.invalidateQueries();
  }

  async function signUp({ organisationName, email, country }: SignUpRequest) {
    const { success } = await authService.signup({
      organisationName,
      email,
      country,
    });

    if (!success) {
      throw new Error("Unexpected response from server");
    }
  }

  async function processInvite(inviteToken: string) {
    const invitation = await authService.getInvitation(inviteToken);

    if (!invitation) {
      throw new Error("This invitation doesn't exist or has expired");
    }

    const { existingUser } = invitation;

    // Expecting new user kill tokens and go to create account page.
    if (!existingUser) {
      const url = `/auth/create-account?token=${inviteToken}`;
      if (user || window.location.pathname + window.location.search !== url) {
        handleSignOut(`/auth/create-account?token=${inviteToken}`);
      }

      return invitation;
    }

    // User is not logged in or logged in to wrong account
    if (!user || user.email !== invitation.user?.email) {
      const redirectUrl = encodeURIComponent("/auth/accept-invite?token=" + inviteToken);
      const url = `/auth/login?token=${inviteToken}&redirectUrl=${redirectUrl}`;
      // kill any tokens and redirect to login with token
      if (user || window.location.pathname + window.location.search !== url) {
        handleSignOut(url);
      }

      return invitation;
    }

    return invitation;
  }
  async function acceptInvite(invitation: UserInvitationByToken, inviteToken: string) {
    const { organisationId } = await authService.acceptInvitation(invitation, inviteToken);

    if (!organisationId) {
      throw new Error("Unexpected response from server");
    }

    Router.push("/");

    await handleSwitchOrganisation(organisationId);
  }

  async function createAccountFromInvite(
    fields: CreateAccountRequest,
    invitation: UserInvitationByToken,
    inviteToken: string
  ) {
    const result = await authService.createAccountFromInvite(fields, inviteToken);
    await signIn({
      credentials: {
        username: invitation.email,
        password: fields.password,
      },
    });

    // accept terms
    await authService.acceptTermsAndConditions();
    Router.push("/");
  }

  const handleSwitchOrganisation = async (organisationId: string) => {
    try {
      const data = await switchOrganisation(api.refreshToken!, organisationId);
      if ((data as LoginErrorResponse).error) {
        console.log(
          "Error switching orgs",
          (data as LoginErrorResponse).error,
          (data as LoginErrorResponse).error_description
        );
        toast({
          title: "Unable to switch org",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
      const {
        access_token: token,
        refresh_token: refreshToken,
        expires_in: maxAge,
        scope,
      } = data as LoginSuccessResponse;
      api.setToken(token);
      api.setRefreshToken(refreshToken);
      persistAuthToken({
        token,
        maxAge,
        refreshToken,
        scope,
      });

      const payload = getTokenPayload(token);

      const { user, organisationAlertOverrideSettings, orgSettings } = await handleLoadUserDetails(payload);

      setOrganisationAlertSettings(organisationAlertOverrideSettings);
      setOrgSettings(orgSettings);
      setUser(user);
      storeUserData({ user, organisationAlertOverrideSettings });
      storeLastOrganisationIdForUser(user.id, organisationId);

      // Clear all cached data
      queryClient.invalidateQueries();
      return true;
    } catch (e) {
      toast({
        title: "Unable to switch org",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      throw e;
    }
  };

  const handleSwitchToCustomer = async () => {
    try {
      const currentOrg = await authService.currentOrganisation();

      const data = await getCustomerAuth(api.refreshToken!, currentOrg.customerId);
      if ((data as LoginErrorResponse).error) {
        console.log("error", data);
        throw new Error((data as LoginErrorResponse).error);
      }

      const {
        access_token: token,
        refresh_token: refreshToken,
        expires_in: maxAge,
        scope,
      } = data as LoginSuccessResponse;

      api.setToken(token);
      api.setRefreshToken(refreshToken);
      api.setScope(scope);
      persistAuthToken({
        token,
        maxAge,
        refreshToken,
        scope,
      });

      // queryClient.invalidateQueries();
    } catch (e) {
      console.log("error getting customer token.");
      console.log(e);
      toast({
        title: "Unable to authenticate as an admin",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      throw e;
    }
  };

  const requestPasswordReset = (email: string) => {
    return authService.requestPasswordReset(email);
  };

  const resetPassword = (token: string, password: string) => {
    return authService.resetPassword(token, password);
  };

  const verifyResetPasswordToken = (token: string) => {
    return authService.verifyResetPasswordToken(token);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isAdmin: Boolean(
          user &&
            [parseInt(UserRoleType.Owner), parseInt(UserRoleType.Admin)].includes(
              user.currentOrganisationRoleId
            )
        ),
        signIn,
        signUp,
        signOut: handleSignOut,
        processInvite,
        acceptInvite,
        createAccountFromInvite,
        isLoadingAuth: isLoading,
        switchOrganisation: handleSwitchOrganisation,
        switchToCustomer: handleSwitchToCustomer,
        orgSettings,
        organisationAlertSettings,
        setOrganisationAlertSettings,
        requestPasswordReset,
        resetPassword,
        verifyResetPasswordToken,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
}

const switchOrganisationFromUrl = async (
  switchOrganisationId: string,
  refreshToken: string,
  toast: ToastType
) => {
  try {
    const switchedData = await switchOrganisation(refreshToken, switchOrganisationId);

    if ((switchedData as LoginErrorResponse).error) {
      toast({
        title: "Unable to switch org",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      console.log(
        "Failed to switch to organisation Id",
        (switchedData as LoginErrorResponse).error,
        (switchedData as LoginErrorResponse).error_description
      );
      return;
    }

    const data = switchedData as LoginSuccessResponse;

    persistAuthToken({
      token: data.access_token,
      refreshToken: data.refresh_token,
      maxAge: data.expires_in,
      scope: data.scope,
    });

    // remove query param from url
    const regex = new RegExp("switchOrganisationId=" + switchOrganisationId + "&?");
    const newUrl = window.location.href.replace(regex, "");

    window.history.replaceState({}, document.title, newUrl);

    const decodedToken = getTokenPayload(data.access_token);
    storeLastOrganisationIdForUser(decodedToken.userId, switchOrganisationId);

    return {
      token: data.access_token,
      refreshToken: data.refresh_token,
      scope: data.scope,
    };
  } catch (error) {
    toast({
      title: "Unable to switch org",
      status: "error",
      isClosable: true,
      position: "top-right",
    });
    console.log("Failed to switch to organisation Id");
    return null;
  }
};
